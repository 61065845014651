<template>
  <BaseCard
    title="datos del contacto"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <ContactForm
      ref="contact-form"
      v-model="contact"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ContactForm from '@/components/contacts/form/ContactForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ContactForm,
  },
  data() {
    return {
      loading: false,
      contact: {},
    }
  },
  computed: {
    clientId() {
      return this.$route.query['client-id'] || null
    },
    providerId() {
      return this.$route.query['provider-id'] || null
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const valid = await this.$refs['contact-form'].validateForm()
      if (!valid) {
        return
      }

      this.contact.client_id = this.clientId
      this.contact.provider_id = this.providerId

      this.loading = true
      try {
        const response = await ApiRestService.create(this.contact, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        await this.$router.replace({ name: 'viewContact', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
