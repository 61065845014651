<template>
  <validation-observer ref="contact-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Nombre"
                rules="required"
              >
                <b-form-group
                  label="Nombre"
                  label-for="name"
                >
                  <b-form-input
                    :value="model.name"
                    name="name"
                    placeholder="Nombre"
                    :state="$getValidationState(validationContext)"
                    @input="update('name', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Apellidos"
                rules="required"
              >
                <b-form-group
                  label="Apellidos"
                  label-for="surname"
                >
                  <b-form-input
                    v-model="model.surname"
                    name="surname"
                    placeholder="Apellidos"
                    :state="$getValidationState(validationContext)"
                    @input="update('surname', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Cargo"
                label-for="job"
              >
                <b-form-input
                  v-model="model.position"
                  name="job"
                  placeholder="Cargo"
                  @input="update('position', $event)"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    v-model="model.email"
                    name="email"
                    placeholder="Email"
                    :state="$getValidationState(validationContext)"
                    @input="update('email', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Teléfono"
                rules="required"
              >
                <b-form-group
                  label="Teléfono"
                  label-for="phone"
                >
                  <b-form-input
                    v-model="model.phone"
                    name="phone"
                    placeholder="Teléfono"
                    :state="$getValidationState(validationContext)"
                    @input="update('phone', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Observaciones"
                label-for="observations"
              >
                <b-form-textarea
                  v-model="model.observations"
                  name="observations"
                  placeholder="Observaciones"
                  rows="7"
                  max-rows="7"
                  @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Contacto de facturación"
                label-cols="auto"
              >
                <b-form-checkbox
                  :checked="model.billing_contact"
                  :value="1"
                  :unchecked-value="0"
                  class="mr-0 mt-50"
                  @input="update('billing_contact', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'

export default {
  name: 'ContactForm',
  mixins: [FormMixin],
  methods: {
    validateForm() {
      return this.$refs['contact-form'].validate()
    },
  },
}
</script>

<style scoped>
</style>
